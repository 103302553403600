@use "../../tokens";

$progressBarHeight: 56px;
.progressContainer {
  .labelContainer {
    font: tokens.$text-title-2xs;
    padding: 0 tokens.$spacing-sm;
    margin-bottom: tokens.$spacing-sm;
  }

  .progressBar {
    background: tokens.$color-grey-05;
    border-radius: calc($progressBarHeight / 2);
    height: $progressBarHeight;
    overflow: hidden;
    position: relative;
    width: 100%;

    &Percentage {
      color: tokens.$color-white;
      position: absolute;
      font: tokens.$text-title-xs;
      left: tokens.$spacing-lg;
      // Center vertically plus some extra for visual balance of the label
      // within the progress bar.
      top: 52%;
      transform: translateY(-50%);
      z-index: 1;
    }

    &Inner {
      background: tokens.$gradient-linear-purple;
      border-radius: calc($progressBarHeight / 2);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: width 200ms linear;
    }
  }
}
