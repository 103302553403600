@use "../../../../../tokens";

.progressLabel {
  margin: tokens.$spacing-xl 0 tokens.$spacing-lg;

  &Indicator {
    font: tokens.$text-title-sm;
    margin-top: tokens.$spacing-sm;

    span {
      color: tokens.$color-purple-70;
    }
  }
}
