@use "../../../../../../tokens";

.wrapper {
  width: 100%;
  height: 100%;
  background-color: tokens.$color-grey-05;
}

.dashboardContent {
  padding-block: tokens.$spacing-2xl;
  padding-inline: tokens.$spacing-xl;
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-lg;
}

.exposuresArea {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-sm;

  .exposuresAreaHeadline {
    font: tokens.$text-body-xl;
    font-weight: 600;
  }

  .exposuresAreaDescription {
    font: tokens.$text-body-md;
  }

  .exposuresFilterWrapper {
    padding-block: tokens.$spacing-md;
  }
}

.exposureList {
  list-style-type: none;
  padding-inline: 0;
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-md;
}

.zeroStateIndicator {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: tokens.$spacing-sm;
  padding: tokens.$spacing-md;
}

.visuallyHidden {
  @include tokens.visually-hidden;
}
