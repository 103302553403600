@use "../../tokens";

.banner {
  background: linear-gradient(
    90deg,
    tokens.$color-purple-90 50%,
    tokens.$color-purple-60
  );
  display: flex;
  justify-content: center;
  padding: tokens.$layout-xs tokens.$layout-sm;
  position: relative;
}

.content {
  h2 {
    font: tokens.$text-title-xs;
    font-weight: 500;
  }

  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-xs;
  color: tokens.$color-white;
  max-width: tokens.$content-lg;
}

.buttons {
  display: flex;
  gap: tokens.$spacing-xs;
  margin-top: tokens.$spacing-md;

  .signButton {
    background-color: tokens.$color-purple-60;
    &:hover {
      background-color: tokens.$color-purple-50;
    }
  }

  .signLink {
    background-color: tokens.$color-purple-60;
    border-radius: tokens.$border-radius-md;
    color: tokens.$color-white;
    font: tokens.$text-body-md;
    font-weight: 500;
    line-height: 1;
    padding: tokens.$spacing-md tokens.$spacing-lg;
    text-align: center;
    text-decoration: none;
    &:hover {
      background-color: tokens.$color-purple-50;
    }
  }

  .dismissButton {
    color: tokens.$color-white;
    &:hover {
      background-color: rgba(tokens.$color-purple-50, 0.2);
    }
  }
}

.closeButton {
  background-color: transparent;
  border-radius: tokens.$border-radius-sm;
  border-style: none;
  color: tokens.$color-white;
  cursor: pointer;
  padding: tokens.$spacing-md;
  position: absolute;
  right: 0;
  top: 0;
}
