@use "../../tokens";

.container {
  display: flex;
}

.tabs {
  display: flex;
  gap: tokens.$spacing-sm;

  &[aria-orientation="vertical"] {
    flex-direction: column;
  }
}

.tab {
  cursor: pointer;
  border-radius: tokens.$border-radius-sm;
  display: flex;
  gap: tokens.$spacing-xs;
  padding: tokens.$spacing-sm;
  position: relative;

  &::after {
    bottom: 0;
    content: "";
    height: tokens.$border-focus-width;
    position: absolute;
    right: 0;
    width: 100%;
  }

  &:hover,
  &:focus {
    outline: 2px solid tokens.$color-blue-50;
    &::after {
      background: none;
    }
  }

  &[aria-selected="true"] {
    color: tokens.$color-purple-70;

    // Only show the tab underline when the item
    // is selected and not hovered or focussed.
    &:not(:hover, :focus) {
      &::after {
        background: tokens.$color-purple-70;
      }
    }
  }
}

.secondary {
  .tabs {
    align-items: flex-start;
    gap: tokens.$spacing-sm;
  }

  .tab {
    color: tokens.$color-grey-40;
    padding: tokens.$spacing-xs tokens.$spacing-xs tokens.$spacing-xs 0;

    &[aria-selected="true"] {
      color: tokens.$color-purple-50;

      // Only show the tab underline when the item
      // is selected and not hovered or focussed.
      &:not(:hover, :focus) {
        &::after {
          background: tokens.$color-purple-50;
          height: 2px;
        }
      }
    }
  }
}
