@use "../../../../../../../tokens";

.container {
  display: grid;
  grid-gap: tokens.$layout-lg;
  flex-direction: row;

  @media screen and (min-width: tokens.$screen-xl) {
    padding-inline: tokens.$layout-lg;
  }

  &.hasIllustration {
    @media screen and (min-width: tokens.$screen-xl) {
      grid-template-columns: 2fr 1fr;
    }
  }

  .breachContentWrapper {
    display: flex;
    flex-direction: column;
    gap: tokens.$spacing-sm;

    .label {
      align-self: flex-start;
      background: rgba(tokens.$color-blue-50, 0.3);
      border-radius: tokens.$border-radius-sm;
      color: tokens.$color-blue-90;
      display: inline-flex;
      font: tokens.$text-body-xs;
      font-weight: 600;
      line-height: 1;
      padding: tokens.$spacing-sm;
    }

    h3 {
      font: tokens.$text-title-2xs;
      padding: tokens.$spacing-sm 0 tokens.$spacing-xs;
    }

    .recommendations {
      p {
        font: tokens.$text-body-sm;
      }
      ol {
        padding-top: tokens.$spacing-md;
        list-style-position: inside;
        padding-left: 0;
      }
      padding-top: tokens.$spacing-md;
    }

    .buttons {
      padding-top: tokens.$spacing-md;
      display: flex;
      gap: tokens.$spacing-md;
      align-items: center;
    }

    .breachItemsWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: tokens.$spacing-xs;

      .breachItem {
        font: tokens.$text-body-sm;
        font-weight: 600;
        background: tokens.$color-grey-10;
        padding: tokens.$spacing-xs tokens.$spacing-sm;
        border-radius: tokens.$border-radius-md;

        .date {
          font-weight: 400;
        }
      }
    }
  }

  .estimatedTime {
    @include tokens.estimated-time;
    padding-top: tokens.$layout-xs;
  }

  .illustrationWrapper {
    align-items: center;

    &.hideOnMobile {
      display: none;

      @media screen and (min-width: tokens.$screen-xl) {
        display: flex;
      }
    }

    img {
      width: 100%;
    }
  }

  .doneContentWrapper {
    display: grid;
    gap: tokens.$layout-xs;
    grid-template-columns: 1fr;

    @media screen and (min-width: tokens.$screen-md) {
      grid-template-columns: 1fr 1fr;
    }

    .doneContent {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: tokens.$spacing-md;
    }
  }
}
