@use "../../../../../../../../../tokens";

.dataBrokerInformationWrapper {
  .exposureCardWrapper {
    margin-top: tokens.$spacing-md;
    padding-bottom: tokens.$spacing-lg;

    .exposureCard {
      display: flex;
      flex-direction: column;
      background-color: tokens.$color-white;
      border: 2px solid rgba(tokens.$color-purple-70, 0.2);
      border-radius: tokens.$border-radius-lg;
      padding: tokens.$layout-sm tokens.$layout-sm tokens.$layout-xs
        tokens.$layout-sm;

      .dataClassesList {
        justify-content: space-between;
        margin-left: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: tokens.$spacing-sm;
        transition: opacity 0.35s ease-in-out;
        opacity: 1;

        &.fadeOut {
          opacity: 0;
        }

        @media screen and (min-width: tokens.$screen-md) {
          grid-template-columns: 1fr 1fr 1fr;
          gap: tokens.$spacing-sm;
        }

        @media screen and (min-width: tokens.$screen-lg) {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: tokens.$layout-xs;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
      }

      .buttonsWrapper {
        margin-top: tokens.$spacing-lg;
        border-top: 2px solid tokens.$color-grey-10;
        padding-top: tokens.$spacing-lg;
        display: flex;
        gap: tokens.$spacing-sm;
      }

      .estimatedTime {
        @include tokens.estimated-time;
        padding-top: tokens.$spacing-sm;
      }
    }
  }

  .header {
    font: tokens.$text-body-lg;
    font-weight: 700;
    transition: opacity 0.35s ease-in-out;
    opacity: 1;

    &.fadeOut {
      opacity: 0;
    }
  }
}

.removalGuideInstructionsWrapper {
  .backArrow {
    all: unset;
    cursor: pointer;
  }
  .headerRemovalGuide {
    padding-top: tokens.$spacing-lg;
    font: tokens.$text-body-xl;
    font-weight: 700;
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
    padding-top: tokens.$spacing-lg;
  }
}

.removalContentSection {
  margin-top: tokens.$layout-md;

  dt {
    font: tokens.$text-body-lg;
    font-weight: 600;

    &.removalGuideInstructions {
      font: tokens.$text-body-md;
      font-weight: 700;
    }
  }

  dd {
    padding-top: tokens.$spacing-sm;
  }

  ol {
    padding-top: tokens.$spacing-sm;

    li {
      margin-bottom: tokens.$spacing-xs;
    }
  }

  button {
    margin-top: tokens.$spacing-lg;
  }
}
