@use "../../tokens";

.button {
  align-items: center;
  font: tokens.$text-body-md;
  font-weight: 600;
  border: 0;
  padding: tokens.$spacing-md tokens.$spacing-lg;
  border-radius: tokens.$border-radius-md;
  cursor: pointer;
  display: inline-flex;
  gap: tokens.$spacing-sm;
  line-height: 1;
  justify-content: center;
  text-align: center;
  text-decoration: none;

  // Loading animation
  .ldsRing {
    margin: 0 auto;
    height: 18px;
    width: 18px;
  }
  .ldsRing div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &.primary {
      border: 4px solid tokens.$color-white;
      border-color: tokens.$color-white transparent transparent transparent;
    }

    &.secondary {
      border: 4px solid tokens.$color-purple-70;
      border-color: tokens.$color-purple-70 transparent transparent transparent;
    }
  }
  .ldsRing div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .ldsRing div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .ldsRing div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &.primary {
    color: tokens.$color-white;
    background-color: tokens.$color-purple-70;

    &:hover {
      background-color: tokens.$color-purple-80;
    }

    &:focus {
      outline: tokens.$border-focus-width solid tokens.$color-purple-30;
    }

    &.destructive {
      color: tokens.$color-white;
      background-color: tokens.$color-red-60;

      &:hover {
        background-color: tokens.$color-red-70;
      }

      &:focus {
        outline: tokens.$border-focus-width solid tokens.$color-red-30;
      }
    }

    &.disabled {
      background: tokens.$color-grey-20;
      pointer-events: none;
    }
  }

  &.secondary {
    color: tokens.$color-purple-70;
    box-shadow: inset 0 0 0 2px tokens.$color-purple-70;
    background-color: transparent;

    &:hover {
      background-color: tokens.$color-purple-70;
      color: tokens.$color-white;

      .ldsRing div {
        &.secondary {
          border: 4px solid tokens.$color-white;
          border-color: tokens.$color-white transparent transparent transparent;
        }
      }
    }

    &.destructive {
      color: tokens.$color-red-70;
      box-shadow: inset 0 0 0 2px tokens.$color-red-70;

      &:hover {
        background-color: tokens.$color-red-70;
        color: tokens.$color-white;
      }
    }

    &.disabled {
      color: tokens.$color-grey-20;
      background: transparent;
      box-shadow: inset 0 0 0 2px tokens.$color-grey-20;
      pointer-events: none;
    }
  }

  &.tertiary {
    background: none;
    color: tokens.$color-purple-70;

    &:hover {
      background-color: rgba(tokens.$color-purple-70, 0.2);
    }
  }

  &.link {
    background: none;
    color: tokens.$color-purple-50;
    font: inherit;
    padding: 0;
    text-decoration: underline;
  }

  &.icon {
    background: none;
    color: tokens.$color-grey-50;
    padding: tokens.$spacing-md;

    &:hover {
      background-color: tokens.$color-grey-05;
    }
  }

  &.isLoading {
    cursor: progress;
    opacity: 0.4;
  }

  &.small {
    line-height: 1.5;
    padding: tokens.$spacing-sm tokens.$spacing-md;

    &.icon {
      padding: tokens.$spacing-sm;
    }
  }

  &.wide {
    padding: tokens.$spacing-md tokens.$spacing-2xl;
  }
}
