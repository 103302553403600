@use "../../../tokens";

.form {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: tokens.$spacing-lg;

  div:first-child {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &.bottom {
    div:first-child {
      flex-direction: column-reverse;
    }
  }

  input {
    font: tokens.$text-body-md;
  }

  button {
    font: tokens.$text-body-md;
    font-weight: 600;
  }

  label {
    font: tokens.$text-body-sm;
    font-weight: 500;
  }
}
