@use "../../../tokens";

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-md;
  padding-bottom: tokens.$spacing-md;
  width: 100%;
}

.signInButton {
  display: block;

  @media screen and (min-width: tokens.$screen-sm) {
    display: none;
  }
}
