@use "../../../../tokens";

.brokersEmphasis {
  display: inline;
  color: tokens.$color-violet-50;
}
.breachesEmphasis {
  display: inline;
  color: tokens.$color-purple-70;
}

.header {
  padding: tokens.$layout-lg tokens.$spacing-md;
  display: flex;
  justify-content: center;
  span {
    width: 100%;
    max-width: tokens.$content-xl;
  }
  @media screen and (min-width: tokens.$content-md) {
    padding: tokens.$layout-lg;
  }
  .title {
    font: tokens.$text-title-lg;
    font-family: "Inter", sans-serif;
    color: tokens.$color-grey-60;
  }
  p {
    color: tokens.$color-grey-40;
    font: tokens.$text-body-xl;
  }
}
.sectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &.grayBG {
    background: tokens.$color-grey-05;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: tokens.$content-xl;
    @media screen and (min-width: tokens.$content-md) {
      padding: tokens.$spacing-2xl 0;
      gap: tokens.$spacing-lg;
    }
    .introContent {
      gap: tokens.$spacing-md;
      display: flex;
      flex-direction: column;
      padding: 0 tokens.$spacing-md;
      h2 {
        color: tokens.$color-grey-60;
        font: tokens.$text-title-md;
        font-family: "Inter", sans-serif;
      }
      p {
        color: tokens.$color-grey-40;
        font: tokens.$text-body-xl;
      }
      .horizontalRule {
        border: 1px solid tokens.$color-grey-20;
        margin: tokens.$spacing-md 0;
      }
    }
    .introCTA {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: tokens.$spacing-lg;
      padding-inline: tokens.$spacing-md;
      padding-block-start: tokens.$spacing-xl;
      width: 100%;
      h3 {
        color: tokens.$color-grey-50;
        font: tokens.$text-title-xs;
        font-family: "Inter", sans-serif;
        flex: auto;
        max-width: tokens.$content-xl;
        @media screen and (min-width: tokens.$screen-md) {
          width: tokens.$content-sm;
        }
      }
    }
  }
  .step {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: tokens.$content-xl;
    &.reversedStep {
      flex-direction: column-reverse;
    }
    .stepTextContainer {
      max-width: tokens.$content-sm;
      padding: tokens.$spacing-sm;
      .stepTitle {
        font: tokens.$text-title-2xs;
        font-family: "Inter", sans-serif;
        padding-block-start: tokens.$spacing-xl;
        @include tokens.uppercase-only-english;
      }
      .stepSubtitle {
        color: tokens.$color-grey-50;
        font: tokens.$text-title-xs;
        font-family: "Inter", sans-serif;
        padding-block-start: tokens.$spacing-md;
      }
      .stepBody {
        color: tokens.$color-grey-50;
        padding-block-start: tokens.$spacing-md;
      }
      .stepGroupTitleAfter1st {
        padding-block-start: tokens.$spacing-2xl;
      }
      .privateInfoRow {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: tokens.$spacing-xs;
        padding-block-start: tokens.$spacing-sm;
        width: 100%;
        .privateInfoText {
          color: tokens.$color-grey-50;
        }
        img {
          width: 30px;
          margin-top: tokens.$spacing-sm;
          margin-right: tokens.$spacing-sm;
        }
        .boldedText {
          font-weight: 700;
        }
      }
    }
    @media screen and (min-width: tokens.$screen-md) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: tokens.$spacing-md;
      gap: tokens.$spacing-lg;
      &.reversedStep {
        flex-direction: row;
      }
      .stepTextContainer {
        max-width: tokens.$content-xs;
      }
      img {
        max-width: tokens.$content-xs;
      }
    }
    @media screen and (min-width: tokens.$screen-lg) {
      padding: tokens.$spacing-xs;
      .stepTextContainer {
        max-width: tokens.$content-sm;
      }
      img {
        max-width: tokens.$content-sm;
      }
    }
    @media screen and (min-width: tokens.$screen-xl) {
      padding: tokens.$spacing-xs;
      .stepTextContainer {
        max-width: tokens.$content-md;
      }
      img {
        max-width: tokens.$content-sm;
      }
    }
  }
  .sectionCTAButton {
    display: grid;
    max-width: tokens.$content-sm;
    width: 100%;
    margin: tokens.$spacing-md 0;
    button {
      padding: tokens.$spacing-md tokens.$spacing-xl;
    }
    a {
      margin-inline: tokens.$spacing-lg;
    }
    &.breachesCTAButton {
      padding-inline: tokens.$spacing-md;
      margin-bottom: tokens.$spacing-xl;
    }
    @media screen and (min-width: tokens.$screen-md) {
      width: unset;
      max-width: unset;
      a {
        margin: unset;
      }
    }
  }
  @media screen and (min-width: tokens.$screen-md) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: unset;
    margin-bottom: tokens.$layout-lg;
    a {
      margin: tokens.$layout-md;
    }
    button {
      margin: tokens.$layout-md;
    }
  }
  @media screen and (min-width: tokens.$screen-lg) {
    padding-inline: tokens.$layout-lg;
  }
}
.footerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: tokens.$color-purple-05;
  padding: tokens.$spacing-2xl tokens.$spacing-md;

  .footerSectionCTA {
    font: tokens.$text-title-2xs;
    font-family: var(--font-inter);
    font-weight: 500;
    margin-bottom: tokens.$spacing-xl;
  }

  .note {
    color: tokens.$color-grey-40;
    font: tokens.$text-body-sm;
    margin-top: tokens.$spacing-sm;
  }
}
