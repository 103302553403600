@use "../../tokens";

.chartContainer {
  text-align: center;
  display: flex;
  gap: tokens.$spacing-lg;
  flex-direction: column;
  align-items: center;

  figcaption {
    text-align: center;
    display: flex;
    align-items: center;
    gap: tokens.$spacing-xs;
    font: tokens.$text-body-xs;
    font-style: italic;

    button {
      @include tokens.question-mark-circle-btn;
    }
  }
}

.modalBodyContent {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-sm;

  .confirmButtonWrapper {
    align-self: center;
    display: flex;
    flex-direction: column;
    min-width: tokens.$content-xs;
    padding-block-start: tokens.$spacing-md;
  }
}

.gutter {
  stroke: tokens.$color-white;
}

.chartWrapper {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-md;

  @media screen and (min-width: tokens.$screen-sm) {
    flex-direction: row;
    gap: tokens.$spacing-xl;
  }

  .chart {
    align-self: center;
    max-width: 200px;
    width: 100%;

    // Font size set in Chart.tsx
    .headingNr {
      font-family: var(--font-inter);
      font-weight: 600;
    }
    .percentageHeadingNr {
      font-family: var(--font-inter);
      font-weight: 600;
    }
    .percent {
      font-family: var(--font-inter);
      font-weight: 300;
    }

    .headingLabel {
      font-family: var(--font-inter);
    }

    .slice {
      @media (prefers-reduced-motion: no-preference) {
        transition: stroke-dashoffset 1s ease;
      }
    }
  }
}

/* stylelint-disable no-descending-specificity */
// We start at 2, because element 1 is .gutter
.slice:nth-child(2) {
  stroke: tokens.$color-purple-90;
}

.slice:nth-child(3) {
  stroke: tokens.$color-purple-70;
}
.slice:nth-child(4) {
  stroke: tokens.$color-purple-50;
}
.slice:nth-child(5) {
  stroke: tokens.$color-purple-30;
}
.slice:nth-child(6) {
  stroke: tokens.$color-purple-10;
}
.slice:nth-child(7) {
  stroke: tokens.$color-purple-80;
}
.slice:nth-child(8) {
  stroke: tokens.$color-purple-60;
}
.slice:nth-child(9) {
  stroke: tokens.$color-purple-40;
}
.slice:nth-child(10) {
  stroke: tokens.$color-purple-20;
}
.slice:nth-child(11) {
  stroke: tokens.$color-violet-90;
}
/* stylelint-enable no-descending-specificity */
