@use "../../../../../tokens";

.stepContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: tokens.$spacing-md;

  h1 {
    font: tokens.$text-title-2xs;

    @media screen and (min-width: tokens.$screen-md) {
      font: tokens.$text-title-xs;
    }
  }

  form {
    width: 100%;
  }

  .explainerTrigger {
    background-color: transparent;
    border-style: none;
    color: tokens.$color-blue-50;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: tokens.$color-blue-70;
    }
  }
}

.dialogContents {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-lg;
  font: tokens.$text-body-md;
  color: tokens.$color-grey-50;

  .confirmButtonWrapper {
    align-self: center;
    display: flex;
    flex-direction: column;
    min-width: tokens.$content-xs;
    padding-block-start: tokens.$spacing-md;
  }

  .list {
    list-style-position: inside;
    display: flex;
    flex-direction: column;
    gap: tokens.$spacing-md;
    padding: 0;

    li::marker {
      display: inline-block;
      font-weight: 700;
    }
  }
}

.stepButtonWrapper {
  align-items: center;
  display: flex;
  gap: tokens.$spacing-md;
  justify-content: flex-end;
  padding-block: tokens.$layout-xs;
  width: 100%;

  @media screen and (min-width: tokens.$screen-sm) {
    padding-block-start: tokens.$layout-sm;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}
