@use "../../../tokens";

.wrapper {
  align-items: center;
  background: linear-gradient(
    90deg,
    tokens.$color-purple-90 50%,
    tokens.$color-purple-60
  );
  color: tokens.$color-white;
  display: flex;
  padding: tokens.$layout-xs tokens.$layout-sm;
  position: relative;
  flex-direction: column;
  gap: tokens.$spacing-sm;
}

.prompt,
.prompt a {
  align-items: center;
  color: tokens.$color-white;
  display: flex;
  gap: tokens.$spacing-sm;
}

.answers {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: tokens.$spacing-sm;

  .answer {
    background-color: tokens.$color-purple-40;

    &:hover {
      background-color: tokens.$color-purple-50;
    }
  }
}

.closeButton {
  background-color: transparent;
  border-radius: tokens.$border-radius-sm;
  border-style: none;
  color: tokens.$color-white;
  cursor: pointer;
  padding: tokens.$spacing-md;
  position: absolute;
  right: 0;
  top: 0;
}
